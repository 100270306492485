









import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'
import moment from 'moment'

const parseTime = (s: string | null) => {
  if (!s) return [null, null]
  let [sh, sm] = String(s).split(':')
  let h = parseInt(sh)
  let m = parseInt(sm)
  if (!isNaN(h) && !isNaN(m)) {
    return [h, m]
  }
  return [null, null]
}

@Component({})
export default class TimeSelect2 extends Vue {
  @Model('change', { default: null }) readonly value!: string | null
  @Prop({ type: String, default: null }) readonly min!: string | null
  @Prop({ type: String, default: null }) readonly max!: string | null
  @Prop({ type: Array, default: null }) readonly items!: string[] | null
  @Prop({ type: Number, default: 10 }) readonly unitMinutes!: number

  time: string | null = null

  get options() {
    if (this.items) return this.items
    let [minH, minM] = parseTime(this.min)
    let [maxH, maxM] = parseTime(this.max)
    let t = moment()
    t.set('hour', minH || 0)
    t.set('minute', minM || 0)
    let end = moment()
    end.set('hour', maxH || 23)
    end.set('minute', maxM || 59)
    let rval = []
    while (end.isAfter(t)) {
      rval.push(t.format('HH:mm'))
      t.add(this.unitMinutes, 'minute')
    }
    return rval
  }

  @Watch('value', { immediate: true })
  onValueChange(value: string | null) {
    if (value) {
      let [h, m] = parseTime(value)
      if (h !== null && m !== null) {
        let sh = h < 10 ? '0' + h : String(h)
        let sm = m < 10 ? '0' + m : String(m)
        this.time = `${sh}:${sm}`
      } else {
        this.time = null
      }
    } else this.time = null
  }

  onChange() {
    this.$emit('change', this.time)
  }
}
