








import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from '@/types/reservation'

@Component
export default class ReservationStateSelect extends Vue {
  @Prop() readonly value!: State
  @Prop({ default: '来店状況' }) readonly label!: string

  get options() {
    return [
      { text: '来店待ち', value: State.New },
      { text: '来店済み', value: State.Served },
      { text: 'キャンセル', value: State.Cancel, disabled: true },
      { text: '来店なし', value: State.NoShow },
    ]
  }
}
