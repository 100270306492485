





































































import { OperationHistory } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DyestuffText extends Vue {
  @Prop({ type: Object, default: null }) readonly item!: OperationHistory

  getRootAmount(item: OperationHistory, amount: number | null) {
    if (amount) {
      if (item.dyestuffAmountRoot) {
        return amount * 0.9
      }
    }
    return amount
  }
  getEndAmount(item: OperationHistory, amount: number | null) {
    if (amount) {
      if (item.dyestuffAmountEnd) {
        return amount * 0.9
      }
    }
    return amount
  }
}
