




























import { Component, Vue } from 'vue-property-decorator'
const payjp = window.Payjp('pk_test_043d43e73c35c62782e66f57')
const elements = payjp.elements()
const cardElement = elements.create('card', {
  style: { base: {} },
})

@Component({})
export default class DevView extends Vue {
  cards: any[] = []

  get counselingURL() {
    return `http://localhost:8082/auth?token=${this.$store.getters.token}`
  }
  async created() {
    this.cards = await this.$api.my.cards().list()
  }
  mounted() {
    cardElement.mount('#card-element')
  }

  async submit() {
    let resp = await payjp.createToken(cardElement)
    console.log(resp)
    if (resp.error) {
      console.error(resp.error)
    }
    console.log(resp.id)

    await this.$api.my.cards().create(resp.id)
  }
}
