

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify/types'
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading'
import { PurchaseHistory } from '@/types'

@Component({
  components: {
    InfiniteLoading,
  },
})
export default class PurchaseHistoryTable extends Vue {
  @Prop({ type: String, default: null }) readonly customer!: string | null
  @Prop({ type: String, default: '450px' }) readonly height!: string | number

  timestamp = new Date().getTime() // コンポーネントの外から更新させるために使用する
  loading = false
  items: PurchaseHistory[] = []
  next: null | string = null

  get identifier() {
    if (!this.customer) return null
    return this.customer + this.timestamp
  }

  get headers() {
    let rval: DataTableHeader[] = [
      {
        text: '購入日',
        value: 'date',
        width: 95,
      },
      {
        text: 'アイテム',
        value: 'goodsShortName',
      },
      {
        text: '数量',
        value: 'quantity',
      },
    ]
    rval.forEach(x => {
      x.sortable = false
    })
    return rval
  }

  @Watch('customer', { immediate: true })
  onCustomerChanged() {
    this.items = []
    this.next = null
  }

  @Watch('items')
  onItemChanged() {
    this.$emit('items', this.items)
  }

  forceReload() {
    this.items = []
    this.next = null
    this.timestamp = new Date().getTime()
  }

  async fetchData($state: StateChanger) {
    if (!this.customer) {
      $state.complete()
      return
    }
    try {
      this.loading = true
      let { results, next } = await (this.next
        ? this.$api.http.get(this.next)
        : this.$api.purchaseHistories().list({ customer: this.customer }))
      this.items = this.items.concat(results)
      // this.$emit('items', this.items)
      this.next = next
      if (this.items.length) $state.loaded()
      if (!this.next) $state.complete()
    } catch (err) {
      console.error(err)
      $state.error()
    }
    this.loading = false
  }
}
