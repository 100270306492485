






























































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'

import {
  Menu,
  SimpleStaff,
  ReserveAailability,
  ScalpCare,
  TreatmentMenu,
} from '@/types'
import RserverForm, {
  Form as ReserverFormType,
  initForm as initReserver,
} from './ReserverForm.vue'
import BasicForm, {
  Form as BasicFormType,
  initForm as initBasic,
} from './BasicForm.vue'

interface Form extends BasicFormType, ReserverFormType {
  [key: string]: any
}

const initForm = () => Object.assign(initBasic(), initReserver())

@Component({
  components: {
    RserverForm,
    BasicForm,
  },
})
export default class ReservationCreateDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ type: Array, required: true }) readonly menus!: Menu[]
  @Prop({ type: Array, required: true }) readonly treatments!: TreatmentMenu[]
  @Prop({ type: Array, required: true }) readonly scalpCares!: ScalpCare[]

  @Prop({ type: Array, required: true }) readonly staffs!: SimpleStaff[]
  @Prop({ type: Array, required: true }) readonly allStaffs!: SimpleStaff[]

  @Prop({ type: String, required: true }) readonly date!: string
  @Prop({ type: String, default: null }) readonly time!: string | null
  @Prop({ type: String, default: null }) readonly initialMenu!: string | null

  @Prop({ type: String, default: null }) readonly shop!: string | null
  @Prop({ type: String, default: null }) readonly staff!: string | null
  @Prop({ type: String, required: true }) readonly minTime!: string
  @Prop({ type: String, required: true }) readonly maxTime!: string
  @Prop({ type: Number, required: true }) readonly unitMinutes!: number
  @Prop({ type: Array, required: true })
  readonly defaultAvailabilities!: ReserveAailability[]

  @Ref()
  readonly cardText!: HTMLElement
  loading = false

  @Ref()
  readonly basicForm!: BasicForm

  @Watch('value', { immediate: true })
  onValueChanged(dialog: boolean) {
    if (this.cardText) this.cardText.scrollTop = 0

    this.form = initForm()
    if (this.time && this.shop) {
      this.form.date = this.date
      this.form.time = this.time
      this.form.shop = this.shop
      this.form.staff = this.staff
    }
    if (dialog) {
      this.form.menu = this.initialMenu
    } else {
      this.basicForm?.clear()
    }
    this.errors = {}
  }

  // @Prop() private staffs!: Array<object>
  form: Form = initForm()
  errors: Dictionary<string[]> = {}

  async create() {
    try {
      this.loading = true
      this.errors = {}
      let form = this.form
      if (form.staff === 'free') {
        form.staff = null
        form.recommendStaffDisabled = true
      }

      let created = await this.$api.reservations().create(form)
      this.$emit('created', created)
      this.$emit('input', false) // close dialog
      this.form = initForm()
    } catch (err) {
      console.error(err)
      this.errors = this.$getValidationErrors(err)
      if (err.status === 400) {
        this.$toast.error('入力項目にエラーがあります。')
      } else {
        this.$toast.error('エラーが発生しました。')
      }
    }
    this.loading = false
  }

  isExistUnsaved() {
    const initial = initForm()
    initial.date = this.date
    if (this.time) initial.time = this.time
    if (this.shop) initial.shop = this.shop
    if (this.staff) initial.staff = this.staff
    const data = this.form
    for (let k in data) {
      // @ts-ignore
      if (data[k] !== initial[k]) {
        // console.log(k, data[k], initial[k])
        return true
      }
    }
    return false
  }

  onClosing() {
    if (this.isExistUnsaved()) {
      if (
        confirm('入力内容が保存されていません。このまま閉じてよろしいですか？')
      ) {
        this.$emit('input', false)
      }
    } else {
      this.$emit('input', false)
    }
  }
}
