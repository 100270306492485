






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ReservationLog } from '@/types/reservation'

@Component
export default class ReservationLogListItem extends Vue {
  @Prop({ type: Object, required: true }) readonly log!: ReservationLog

  get editorName() {
    let editor = this.log.editor
    if (!editor) return '(システム管理者)'
    if (editor.isShopUser) return `${editor.uid} (${editor.shopName})`
    return editor.username
  }
}
