



























































// import moment from 'moment'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading'
import { Reservation } from '@/types'
import DateSelect from '../DateSelect.vue'

@Component({
  components: {
    InfiniteLoading,
    DateSelect,
  },
})
export default class ReservationSearchBox extends Vue {
  @Prop({ type: String, required: true }) readonly shop!: string
  @Prop({ type: String, required: true }) readonly date!: string

  dialog = false
  loading = false
  search = ''
  dateFrom = ''
  dateTo = ''
  next: null | string = null
  items: Reservation[] = []

  get headers() {
    return [
      { text: '来店日', value: 'date' },
      { text: '時間', value: 'time' },
      { text: '状態', value: 'stateText' },
      { text: 'お客様ID', value: 'customerCode' },
      { text: 'セイ', value: 'familyNameKana' },
      { text: 'メイ', value: 'givenNameKana' },
      { text: '登録日時', value: 'createdAt' },
    ]
  }

  get queryParams() {
    return {
      search: this.search,
      shop: this.shop,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    }
  }

  get queryId() {
    return JSON.stringify(this.queryParams)
  }

  // @Watch('date', { immediate: true })
  // onDateChanged(date: string) {
  //   let d = date ? moment(date) : moment()
  //   this.dateTo = d.add(1, 'month').format('YYYY-MM-DD')
  //   this.dateFrom = ''
  // }

  @Watch('dialog')
  onDialogChanged(value: boolean) {
    if (!value) {
      this.next = null
      this.items = []
    }
  }

  @Watch('queryId')
  onQueryChanged() {
    this.next = null
    this.items = []
  }

  async onInfinite(state: StateChanger) {
    this.loading = true
    try {
      let { results, next } = await (this.next
        ? this.$api.http.get(this.next)
        : this.$api.reservations().list(this.queryParams))
      this.items = this.items.concat(results)
      this.next = next
      if (results.length > 0) state.loaded()
      if (!next) state.complete()
    } catch (err) {
      console.error(err)
      state.error()
    }
    this.loading = false
  }

  onClickRow(data: any) {
    this.pushDate(data.date)
  }

  pushDate(date: string) {
    this.dialog = false
    this.$router.push({
      path: `/timeline/${this.shop}`,
      query: Object.assign({}, this.$route.query, { date }),
    })
  }
}
