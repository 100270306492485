var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',[(_vm.item)?_c('v-layout',[_c('v-flex',{attrs:{"shrink":""}},[_vm._v(" 根元： ")]),_c('v-flex',{attrs:{"grow":""}},[(_vm.item.usedDyestuffRoots.length)?_c('span',[_vm._v(" 1剤: "+_vm._s(_vm.item.usedDyestuffRoots .map( function (ref) {
	var name = ref.name;
	var amount = ref.amount;

	return (name + "(" + (_vm.getRootAmount(_vm.item, amount)) + "g)");
} ) .join(', '))+" ")]):_vm._e(),_c('br'),(_vm.item.usedSolutionRoots.length)?_c('span',[_vm._v(" 2剤 "+_vm._s(_vm.item.usedSolutionRoots .map( function (ref) {
	var percentage = ref.percentage;
	var amount = ref.amount;

	return (percentage + "%(" + (_vm.getRootAmount(_vm.item, amount)) + "g)");
} ) .join(', '))+" ")]):_vm._e()])],1):_vm._e()],1),_c('v-layout',[(_vm.item)?_c('v-layout',[_c('v-flex',{attrs:{"shrink":""}},[_vm._v(" 毛先: ")]),_c('v-flex',{attrs:{"grow":""}},[(_vm.item.usedDyestuffEnds.length)?_c('span',[_vm._v(" 1剤: "+_vm._s(_vm.item.usedDyestuffEnds .map( function (ref) {
	var name = ref.name;
	var amount = ref.amount;

	return (name + "(" + (_vm.getEndAmount(_vm.item, amount)) + "g)");
} ) .join(', '))+" ")]):_vm._e(),_c('br'),(_vm.item.usedSolutionEnds.length)?_c('span',[_vm._v(" 2剤 "+_vm._s(_vm.item.usedSolutionEnds .map( function (ref) {
	var percentage = ref.percentage;
	var amount = ref.amount;

	return (percentage + "%(" + (_vm.getEndAmount(_vm.item, amount)) + "g)");
} ) .join(', '))+" ")]):_vm._e()])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }