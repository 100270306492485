




import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import debounce from 'lodash/debounce'

@Component
export default class RepeaterCount extends Vue {
  @Prop({ type: String, required: true }) readonly shop!: string
  @Prop({ type: String, required: true }) readonly date!: string

  isLoading = false
  count = 0

  get params() {
    return {
      shop: this.shop,
      fromCreated: moment(this.date)
        .startOf('day')
        .toISOString(),
      toCreated: moment(this.date)
        .endOf('day')
        .toISOString(),
      // reservedBy: 6,
      reserveInShop: true,
      active: true,
    }
  }

  _fetch!: ReturnType<typeof debounce>

  @Watch('date', { immediate: false })
  onDateChanged() {
    this.isLoading = true
    this._fetch()
  }

  created() {
    this._fetch = debounce(this.fetchData, 2000)
    this._fetch()
  }

  async fetchData() {
    try {
      this.isLoading = true
      this.count = await this.$api.reservations().count(this.params)
    } catch (err) {
      console.error(err)
    }
    this.isLoading = false
  }
}
