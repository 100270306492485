











import { Reservation, State } from '@/types'
import moment from 'moment'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class CustomerCount extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly reserves!: Reservation[]

  isRT(r: Reservation) {
    // 総来店数が4以上。かつ、今回の来店が前回から45日以内。

    if (!r.previousReservedDate) return false
    return (
      r.sumVisited > 3 &&
      moment(r.date).diff(r.previousReservedDate, 'days') <= 45
    )
  }

  isHT(r: Reservation) {
    // 総来店数が2or3。かつ、今回の来店が前回から45日以内。表示なしor 6 or 7 のいずれか
    if (!r.previousReservedDate) return false
    return (
      [2, 3].includes(r.sumVisited) &&
      moment(r.date).diff(r.previousReservedDate, 'days') <= 45
    )
  }

  get htCount() {
    return this.liveReseves.filter(x => this.isHT(x)).length
  }

  get rtCount() {
    return this.liveReseves.filter(x => this.isRT(x)).length
  }

  get unregisteredCount() {
    return this.liveReseves.filter(x => !x.customer).length
  }

  get newcomerCount() {
    return (
      this.liveReseves.filter(x => !x.previousReservedDate).length -
      this.unregisteredCount
    )
  }

  get otherCount() {
    return (
      this.total -
      this.htCount -
      this.rtCount -
      this.newcomerCount -
      this.unregisteredCount
    )
  }

  get total() {
    return this.liveReseves.length
  }

  get liveReseves() {
    return this.reserves.filter(
      x => x.state !== State.Cancel && !x.isRemoved && x.state !== State.NoShow
    )
  }
}
