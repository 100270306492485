





























































import { Information } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VueMarkdown from 'vue-markdown'

@Component({
  components: { VueMarkdown },
})
export default class InformatonDialog extends Vue {
  @Prop({ type: Array, required: true }) readonly informations!: Information[]

  dialog = false
  detailDialog = false
  selected: null | Information = null

  select(info: Information) {
    this.selected = info
    this.detailDialog = true
  }
}
