





























import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'

import DyestuffForm2, { Form, initForm } from './DyestuffForm2.vue'

@Component({
  components: {
    DyestuffForm2,
  },
})
export default class ReservationOperationHistoryDyestuffFormList2 extends Vue {
  @Model('change', { type: Array, default: () => [] })
  readonly value!: Form[] | null
  @Prop({ type: Array, default: () => [] }) readonly errors!: Dictionary<
    string[]
  >
  form: Form[] = [initForm()]

  @Watch('value', { immediate: true, deep: true })
  onValueChange(value: Form[] | null) {
    if (!value) this.form = [initForm()]
    else if (value !== this.form)
      this.form = value.map(x => Object.assign({}, x))

    // NOTE: 空のフォームを1つ追加しておく
    if (this.form.length === 0) this.form = [initForm()]
  }

  onChange(idx: number, val: Form) {
    this.form.splice(idx, 1, val)
    this.emitForm()
  }

  push() {
    this.form.push(initForm())
    this.emitForm()
  }
  pop() {
    this.form.pop()
    this.emitForm()
  }

  emitForm() {
    this.$emit('change', this.form)
  }
}
