















































































































































































































































import {
  Component,
  Prop,
  Vue,
  Watch,
  Ref,
  ModelSync,
} from 'vue-property-decorator'
import { Dictionary } from 'lodash'
import moment from 'moment'
import {
  Reservation,
  State,
  Menu,
  // MenuOption,
  SimpleStaff,
  Dyestuff,
  ReserveAailability,
  OperationHistory,
  ReservedBy,
  CancelPrice,
  ScalpCare,
  TreatmentMenu,
} from '@/types'
import RserverForm, { Form as ReserverFormType } from './ReserverForm.vue'
import BasicForm, {
  initForm as initBasicForm,
  Form as BasicFormType,
} from './BasicForm.vue'

import OperationHistoryTable from './OperationHistoryTable.vue'
import OperationHistoryFrom, {
  Form as OpHistFormType,
} from './OperationHistoryForm.vue'
import LogList from './LogList.vue'
import CancelConfirmDialog from './CancelConfirm.vue'
import UpdateConfirmDialog from './UpdateConfirm.vue'
import DateSelect from '@/components/DateSelect.vue'
import PurchaseHistoryTable from '@/components/PurchaseHistoryTable.vue'
import PurchaseDialog from '@/components/PurchaseDialog.vue'
import TicketForm from './TicketForm.vue'

export type ReserveUpdateForm = BasicFormType & ReserverFormType

@Component({
  components: {
    RserverForm,
    BasicForm,
    OperationHistoryTable,
    OperationHistoryFrom,
    LogList,
    CancelConfirmDialog,
    UpdateConfirmDialog,
    DateSelect,
    PurchaseHistoryTable,
    PurchaseDialog,
    TicketForm,
  },
})
export default class ReservationUpdateDialog extends Vue {
  // @Prop({ default: false }) readonly value!: boolean
  @ModelSync('value', 'input', { type: Boolean, default: false })
  dialog!: boolean
  @Prop({ type: Object, default: null }) readonly reserve!: Reservation | null

  @Prop({ required: true }) readonly menus!: Menu[]
  @Prop({ required: true }) readonly treatments!: TreatmentMenu[]
  @Prop({ type: Array, required: true }) readonly scalpCares!: ScalpCare[]

  @Prop({ required: true }) readonly staffs!: SimpleStaff[]
  @Prop({ required: true }) readonly allStaffs!: SimpleStaff[]
  @Prop({ required: true }) readonly dyestuffs!: Dyestuff[]
  @Prop({ type: String, required: true }) readonly minTime!: string
  @Prop({ type: String, required: true }) readonly maxTime!: string
  @Prop({ type: Number, required: true }) readonly unitMinutes!: number
  @Prop({ type: Array, required: true })
  readonly defaultAvailabilities!: ReserveAailability[]
  @Prop({ type: Boolean, default: false }) readonly isFufuR!: boolean

  @Ref() readonly purchaseTable!: PurchaseHistoryTable
  @Ref() readonly cardText!: HTMLElement

  @Ref() readonly dialogTop!: HTMLElement
  @Ref() readonly tableTop!: HTMLElement
  @Ref() readonly reserverTop!: HTMLElement
  @Ref() readonly basicTop!: HTMLElement
  @Ref() readonly opTop!: HTMLElement
  @Ref() readonly purchaseHistoryTop!: HTMLElement
  @Ref() readonly ticketTop!: HTMLElement

  reserverForm: null | ReserverFormType = null
  basicForm: null | BasicFormType = null
  opHistForm: null | OpHistFormType = null
  errors: Dictionary<string[]> = {}
  loading = false
  nextNote = ''
  cancelPrice: CancelPrice | null = null
  updateConfirmDialog = false
  customerForm = {
    enableStarterPass: false,
    starterPassFrom: null as null | string,
  }
  hasPurchaseItem = false
  oldReserve = null as null | Reservation

  get royaltyRank() {
    const r = this.reserve
    if (!r) return null
    const rank = r.royaltyRank
    switch (rank) {
      case 5:
        return 'VIP'
      case 4:
        return 'ダイヤモンド'
      case 3:
        return 'プラチナ'
      case 2:
        return 'ゴールド'
      case 1:
        return 'シルバー'
      case null:
        return 'なし'
      default:
        return rank
    }
  }

  get activeStarterPass() {
    // NOTE: 2021-02-19 以降にデプロイ予定なので無効にしておく
    return true
  }

  get id() {
    return this.reserve?.id
  }

  get reserverName() {
    return `${this.reserve?.familyNameKana} ${this.reserve?.givenNameKana}`
  }

  get ecSiteUserNo() {
    const no = this.reserve?.ecSiteUserCode
    if (no) return `ECサイトユーザID: ${no}`
    return ''
  }

  get enableCancel() {
    return !this.isRemoved && this.reserve?.state == State.New
  }

  get isCancelled() {
    return this.reserve?.state === State.Cancel
  }

  get isRemoved() {
    return this.reserve?.isRemoved
  }

  get operationHistory() {
    return this.reserve?.operationHistory || null
  }

  get menu() {
    return this.basicForm?.menu || this.reserve?.menu || null
  }
  set menu(value: null | string) {
    if (this.basicForm) {
      this.basicForm.menu = value
    }
  }
  get treatment() {
    return this.basicForm?.treatment || this.reserve?.treatment || null
  }
  set treatment(value: null | string) {
    if (this.basicForm) this.basicForm.treatment = value
  }
  get scalpCare() {
    return this.basicForm?.scalpCare || this.reserve?.scalpCare || null
  }
  set scalpCare(value: null | number) {
    if (this.basicForm) this.basicForm.scalpCare = value
  }

  @Watch('value')
  onValueChanged(value: boolean) {
    if (this.cardText) this.cardText.scrollTop = 0
    if (!value) {
      this.nextNote = ''
      this.errors = {}
    }
    this.cancelPrice = null
  }

  @Watch('reserve', { immediate: true })
  onReserveChanged(reserve: null | Reservation) {
    if (reserve) {
      const oldReserve = this.oldReserve
      const checkOverWritten = this.dialog && oldReserve?.id === reserve.id
      if (!this.basicForm || !checkOverWritten) {
        this.basicForm = initBasicForm()
      }
      let form = this.basicForm
      Object.keys(form).forEach(k => {
        // @ts-ignore
        if (!checkOverWritten || oldReserve[k] !== reserve[k]) {
          // @ts-ignore
          form[k] = reserve[k]
        }
      })

      if (!checkOverWritten || !oldReserve) {
        let enable = Boolean(reserve.starterPassFrom)
        this.customerForm.enableStarterPass = enable
        this.customerForm.starterPassFrom = enable
          ? reserve.starterPassFrom
          : moment().format('YYYY-MM-DD')
      }
      this.oldReserve = Object.assign({}, reserve)
    } else {
      this.basicForm = initBasicForm()

      this.oldReserve = null
    }
  }

  @Watch('form', { deep: true })
  onFormChanged(value: ReserveUpdateForm) {
    console.log('form changed', value)
  }

  get createdAt() {
    return this.reserve?.createdAt
  }
  get updatedAt() {
    return this.reserve?.updatedAt
  }
  get customer() {
    return this.reserve?.customer || null
  }
  get requiredPhoneNumber() {
    return this.basicForm?.reservedBy !== ReservedBy.Hotpepper
  }

  onFetchedHistory(items: OperationHistory[]) {
    let date = this.reserve?.date || ''
    if (!date) {
      this.nextNote = ''
      return
    } else {
      items = items.filter(x => x.date < date)
      items.sort((a, b) => b.date.localeCompare(a.date))
      let item = items.filter(x => x.isActive)[0]
      if (item) {
        this.nextNote = item.noteForNext
      }
    }
  }

  async fetchPrice() {
    try {
      this.loading = true
      return await this.$api.reservations(this.id).cancelPrice()
    } catch (err) {
      this.$toast.error('キャンセル料の取得に失敗しました。')
      throw err
    } finally {
      this.loading = false
    }
  }

  async preUpdate() {
    // NOTE: 更新前にキャンセル料がかかるか確認する
    let reserve = this.reserve as Reservation
    let form = Object.assign(
      {},
      this.reserverForm,
      this.basicForm,
      this.opHistForm
    )
    if (!reserve.paid) {
      this.update()
      return
    }

    const menuChanged =
      form.menu !== reserve.menu ||
      form.treatment !== reserve.treatment ||
      form.addtionalDyestuff !== reserve.addtionalDyestuff

    if (
      menuChanged &&
      !confirm('合計金額が変更されたため、事前決済を払い戻します。')
    ) {
      return
    }

    const scheduleChanged =
      form.shop !== reserve.shop ||
      form.date !== reserve.date ||
      form.time !== reserve.time

    if (!scheduleChanged) {
      this.update()
      return
    }
    let price = await this.fetchPrice()
    if (!price.changeDateTime) {
      this.update()
      return
    }
    this.cancelPrice = price
    this.updateConfirmDialog = true
  }

  async updateStarterPass() {
    const reserve = this.reserve

    if (!reserve || !reserve.customer) return
    const form = this.customerForm
    if (!form.enableStarterPass) {
      form.starterPassFrom = null
    }
    if (reserve.starterPassFrom !== form.starterPassFrom) {
      await this.$api
        .customers(reserve.customer)
        .partialUpdate({ starterPassFrom: form.starterPassFrom })
    }
  }

  async update(nocharge = true) {
    try {
      this.loading = true
      this.errors = {}
      await this.updateStarterPass()
      let data = Object.assign({}, this.reserverForm, this.basicForm)
      if (data.staff === 'free') {
        data.staff = null
        data.recommendStaffDisabled = true
      }
      if (!this.requiredPhoneNumber) {
        data.phoneNumberRequired = false
      }
      data.nocharge = nocharge
      let oh = this.opHistForm
      if (oh) {
        // NOTE: 未入力の要素を除去する
        oh.usedDyestuffRoots = oh.usedDyestuffRoots.filter(
          x => x.amount && x.dyestuff
        )
        oh.usedDyestuffEnds = oh.usedDyestuffEnds.filter(
          x => x.amount && x.dyestuff
        )
        oh.usedSolutionRoots = oh.usedSolutionRoots.filter(
          x => x.amount && x.percentage
        )
        oh.usedSolutionEnds = oh.usedSolutionEnds.filter(
          x => x.amount && x.percentage
        )
      }
      let updated = await this.$api
        .reservations(this.id)
        .update(Object.assign({}, data, { operationHistory: oh }))
      this.$emit('updated', updated)
      this.dialog = false
    } catch (err) {
      console.error(err)
      this.errors = this.$getValidationErrors(err)
      if (err.status === 400) {
        this.$toast.error('入力項目にエラーがあります。')
      } else {
        this.$toast.error('エラーが発生しました。')
      }
    }
    this.loading = false
  }

  async remove() {
    if (!confirm('予約を削除しますか？')) return
    try {
      this.loading = true
      await this.$api.reservations(this.id).delete()
      this.$toast.success('予約を削除しました。')
      // this.$emit('input', false) // close dialog
      this.dialog = false
    } catch (err) {
      console.error(err)
      this.$toast.error('予約の削除に失敗しました。')
    }
    this.loading = false
  }

  async restore() {
    try {
      this.loading = true
      await this.$api.reservations(this.id).restore()
      this.$toast.success('予約を復元しました。')
      // NOTE: 画面を一度閉じないと、なぜか反映されない
      // this.$emit('input', false) // close dialog
      this.dialog = false
    } catch (err) {
      console.error(err)
      this.$toast.error('予約の復元に失敗しました。')
    }
    this.loading = false
  }

  goReserverTop() {
    let { top } = this.reserverTop.getBoundingClientRect()

    this.scroll(top)
  }
  goBasicTop() {
    let { top } = this.basicTop.getBoundingClientRect()
    this.scroll(top)
  }
  goOpTop() {
    let { top } = this.opTop.getBoundingClientRect()
    this.scroll(top)
  }
  goTableTop() {
    let { top } = this.tableTop.getBoundingClientRect()
    this.scroll(top)
  }
  goGoodsTop() {
    let { top } = this.purchaseHistoryTop.getBoundingClientRect()
    this.scroll(top)
  }
  goTicketTop() {
    let { top } = this.ticketTop.getBoundingClientRect()
    this.scroll(top)
  }
  scroll(top: number) {
    let dialogRect = this.dialogTop.getBoundingClientRect()

    this.cardText.scrollTop = top - dialogRect.top + this.cardText.scrollTop
  }

  isExistUnsaved() {
    const reserve = this.reserve
    if (!reserve) return false
    const data = Object.assign({}, this.reserverForm, this.basicForm)
    for (let k in data) {
      // @ts-ignore
      if (data[k] !== reserve[k]) {
        // console.log(k, data[k], reserve[k])
        return true
      }
    }
    const oh = this.reserve?.operationHistory
    if (!oh) return false
    const ohForm = this.opHistForm
    for (let k in ohForm) {
      // @ts-ignore
      if (ohForm[k] !== oh[k]) {
        // console.log(k, ohForm[k], oh[k])
        return true
      }
    }
    return false
  }

  onClosing() {
    if (this.isExistUnsaved()) {
      if (
        confirm('入力内容が保存されていません。このまま閉じてよろしいですか？')
      ) {
        this.dialog = false
        return true
      }
    } else {
      this.dialog = false
      return true
    }
    return false
  }

  onPressClose() {
    const ok = this.onClosing()
    this.dialog = !ok
  }
}
