




























































import { CancelPrice } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ReservationUpdateConfirmDialog extends Vue {
  @Prop({ type: Object, default: null }) price!: null | CancelPrice

  get enableNoChargeCancel() {
    return Boolean(this.price?.cancel)
  }

  charge() {
    this.$emit('input', false)
    this.$emit('charge')
  }
  nocharge() {
    this.$emit('input', false)
    this.$emit('nocharge')
  }
}
