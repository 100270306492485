









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class FlotingBox extends Vue {
  @Prop({ required: true }) readonly left!: number | string
  @Prop({ required: true }) readonly width!: number | string
  @Prop({ required: true }) readonly height!: number | string

  px(x: string | number) {
    return `${Number(x)}px`
  }
}
