



















































import { Goods, PurchaseHistory } from '@/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

type SelectedItem = {
  goods: number
  quantity: number
}

@Component
export default class PurchaseDialog extends Vue {
  @Prop({ type: String, default: null }) readonly customer!: string | null
  @Prop({ type: String, required: true }) readonly date!: string
  @Prop({ type: String, required: true }) readonly shop!: string

  dialog = false
  items: PurchaseHistory[] = []
  goods: Goods[] = []

  selectedGoods: SelectedItem[] = []
  isSubmitting = false

  @Watch('dialog', { immediate: true })
  onDialogChanged(dialog: boolean) {
    this.items = []
    this.goods = []
    this.selectedGoods = []
    if (dialog) {
      this.fetchData()
    }
  }

  onChange(goods: number, value: boolean) {
    const idx = this.selectedGoods.findIndex(x => x.goods === goods)
    if (idx < 0) {
      this.selectedGoods.push({ goods, quantity: 1 })
    } else {
      this.selectedGoods.splice(idx, 1)
    }
  }

  async fetchData() {
    if (!this.customer) return
    try {
      const [goods, hist] = await Promise.all([
        this.$api.goods().list({ shops: this.shop }),
        this.$api
          .purchaseHistories()
          .list({ date: this.date, customer: this.customer }),
      ])
      this.goods = goods.results
      this.items = hist.results

      this.goods.forEach(goods => {
        const item = this.items.find(item => item.goods === goods.id)
        if (item) {
          this.selectedGoods.push({
            goods: item.goods,
            quantity: item.quantity,
          })
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  inc(goods: number) {
    const idx = this.selectedGoods.findIndex(x => x.goods === goods)
    if (idx >= 0) {
      const quantity = this.selectedGoods[idx].quantity || 0
      this.selectedGoods.splice(idx, 1, { goods, quantity: quantity + 1 })
    }
  }
  dec(goods: number) {
    const idx = this.selectedGoods.findIndex(x => x.goods === goods)
    if (idx >= 0) {
      const quantity = this.selectedGoods[idx].quantity || 0
      this.selectedGoods.splice(idx, 1, { goods, quantity: quantity - 1 })
    }
  }

  async submit() {
    const shop = this.shop
    const date = this.date
    const customer = this.customer
    const items = this.items
    const newItems = this.selectedGoods.filter(x => Boolean(x.quantity))
    const deleting = items.filter(x => !newItems.some(y => y.goods === x.goods))
    const creating = newItems.filter(x => !items.some(y => y.goods === x.goods))
    const updating = items
      .filter(x =>
        newItems.find(y => y.goods === x.goods && x.quantity !== y.quantity)
      )
      .map(x =>
        Object.assign(
          {},
          x,
          newItems.find(y => y.goods === x.goods)
        )
      )
    const api = this.$api.purchaseHistories
    const reqDels = deleting.map(x => api(x.id).delete())
    const reqPuts = creating.map(x =>
      api().create(Object.assign({ shop, date, customer }, x))
    )
    const reqPosts = updating.map(x => api(x.id).update(x))
    this.isSubmitting = true

    try {
      await Promise.all([...reqDels, ...reqPuts, ...reqPosts])
      this.$emit('updated')
      this.dialog = false
    } catch (err) {
      console.error(err)
      this.$toast.error('更新に失敗しました。')
    }
    this.isSubmitting = false
  }
}
