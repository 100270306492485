














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading'
import { ReservationLog } from '@/types/reservation'
import LogListItem from './LogListItem.vue'

@Component({
  components: {
    LogListItem,
    InfiniteLoading,
  },
})
export default class ReservationLogList extends Vue {
  @Prop({ type: String, default: null }) readonly reservationId!: string

  logs: ReservationLog[] = []
  next: string | null = null

  @Watch('reservationId')
  onChangeId(value: string | null) {
    this.logs = []
    this.next = null
  }

  async onInfinite($state: StateChanger) {
    console.log('onInfinite loglist')
    try {
      let { results, next } = await (this.next
        ? this.$api.http.get(this.next)
        : this.$api
            .reservations(this.reservationId)
            .logs()
            .list())
      this.logs = this.logs.concat(results)
      this.next = next
      if (results.length) $state.loaded()
      if (!next) $state.complete()
    } catch (err) {
      console.error(err)
      $state.error()
    }
  }
}
