
































































































































































































import moment from 'moment'

import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'
import VueMarkdown from 'vue-markdown'

// import CustomerCreateDialog from '@/components/CustomerCreateDialog.vue'
import MonthlyVisitorDialog from '@/components/MonthlyVisitorDialog.vue'
import RejectCounter from '../RejectCounter.vue'
import SearchBox from '../Reservation/SearchBox.vue'
import TimeListDialog from '../Reservation/TimeListDialog.vue'
import InformationDialog from '../InformationDialog.vue'
import CustomerCount from './CustomerCount.vue'
import RepeaterCount from './RepeaterCount.vue'

import FilterUtil from '@/filter-util'
import { Reservation, State, Menu, Information } from '@/types'

export const filterUtil = new FilterUtil({
  // @ts-ignore
  visibleStates: { type: Array },
  date: { type: String, default: '' },
  showFrame: { type: Boolean, default: true },
})

type FilterType = {
  visibleStates: string[]
  date: string
  showFrame: boolean
}

@Component({
  components: {
    RejectCounter,
    // CustomerCreateDialog,
    MonthlyVisitorDialog,
    SearchBox,
    TimeListDialog,
    VueMarkdown,
    InformationDialog,
    CustomerCount,
    RepeaterCount,
  },
})
export default class TimelineToolbar extends Vue {
  @Prop({ type: String, required: true }) readonly shopId!: string
  @PropSync('date', { type: String, default: '' }) currentDate!: string
  @PropSync('visibleStates', { type: Array, default: () => [] })
  states!: string[]
  @PropSync('showFrame', { type: Boolean, default: true })
  syncedShowFrame!: boolean
  @Prop({ type: String, required: true }) readonly shopName!: string
  @Prop({ type: Array, required: true }) readonly reserves!: Reservation[]
  @Prop({ type: Array, required: true }) readonly menus!: Menu[]
  @Prop({ type: Number, default: 0 }) readonly totalOverflow!: number

  query: null | Dictionary<unknown> = null
  dateMenu = false
  // customerCreateDialog = false
  filter: FilterType = {
    date: '',
    visibleStates: [],
    showFrame: true,
  }
  informations: Information[] = []

  get headlines() {
    return this.informations.filter(x => x.isHeadline)
  }

  get reserveCount() {
    return this.reserves.filter(
      x => x.state !== State.Cancel && !x.isRemoved && x.state !== State.NoShow
    ).length
  }
  get hotpepperPointUsers() {
    return this.reserves
      .filter(x => x.hotpepperPoint && x.state !== 5 && !x.isRemoved)
      .map(x => ({
        start: x.start,
        name: x.fullNameKana,
        point: x.hotpepperPoint,
      }))
  }

  @Watch('$route.query')
  onQueryChanged(query: Dictionary<string | (string | null)[]>) {
    this.queryToFilter()
  }
  @Watch('shopId', { immediate: true })
  onShopIdChanged() {
    this.fetchInformations()
  }

  created() {
    this.queryToFilter()
  }

  async fetchInformations() {
    this.informations = []
    try {
      let { results } = await this.$api
        .informations()
        .list({ shop: this.shopId })
      this.informations = results
    } catch (err) {
      console.error(err)
    }
  }

  queryToFilter() {
    // NOTE: $route.queryからfilterの値をセットする
    this.query = filterUtil.parseQueryDict(this.$route.query) as Record<
      string,
      any
    >
    Object.assign(this.filter, this.query)

    // NOTE: currentDate/statesも親コンポーネントで参照してるので更新する
    if (moment(this.filter.date).isValid()) {
      this.currentDate = moment(this.filter.date).format('YYYY-MM-DD')
    } else {
      this.currentDate = moment().format('YYYY-MM-DD')
      this.filter.date = this.currentDate
    }
    this.states = this.filter.visibleStates
    this.syncedShowFrame = this.filter.showFrame
  }

  updateQuery() {
    // NOTE: filterの値から$route.queryを更新させる
    // filterを一度経由させないとinputイベント等の入力途中の値を捨てたいケースに対応できない
    let query = filterUtil.mergeQueryDict(
      // @ts-ignore
      this.query,
      this.filter
    ) as Dictionary<any>
    this.$router.push({ query })
  }

  goToday() {
    this.filter.date = moment().format('YYYY-MM-DD')
    this.updateQuery()
  }
  goNextDate() {
    this.filter.date = moment(this.currentDate)
      .add(1, 'days')
      .format('YYYY-MM-DD')
    this.updateQuery()
  }
  goPrevDate() {
    this.filter.date = moment(this.currentDate)
      .add(-1, 'days')
      .format('YYYY-MM-DD')
    this.updateQuery()
  }

  get shiftURL() {
    return process.env.VUE_APP_UGLY_ADMIN_BASE_URL + 'shift'
  }
  get shiftPlanURL() {
    return process.env.VUE_APP_UGLY_ADMIN_BASE_URL + 'shift-plan'
  }
  logout() {
    this.$store.dispatch('clear')
    this.$router.push('/login')
  }
}
