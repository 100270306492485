













import moment from 'moment'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class TickerLine extends Vue {
  @Prop({ type: String, required: true }) readonly height!: string
  @Prop({ type: String, required: true }) readonly start!: string
  @Prop({ type: Number, required: true }) readonly pixelPerMinute!: number

  private _intervalID: null | number = null
  now = new Date()

  get started() {
    if (!this.start) return null
    return moment(this.start)
  }
  get left() {
    if (!this.started) return ''
    let m = -this.started.diff(this.now, 'minute')
    return this.pixelPerMinute * m + 'px'
  }

  created() {
    this._intervalID = window.setInterval(() => {
      this.now = new Date()
    }, 10000)
  }
  beforeDestroy() {
    if (this._intervalID !== null) clearInterval(this._intervalID)
  }
}
