



























































import { CancelPrice } from '@/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ReservationCancelConfirmDialog extends Vue {
  @Prop({ type: String, default: '' }) id!: string

  dialog = false
  loading = false
  price: null | CancelPrice = null

  get enableNoChargeCancel() {
    return Boolean(this.price?.cancel)
  }

  @Watch('dialog')
  onValueChanged(value: boolean) {
    this.price = null
    if (value && this.id) {
      this.fetchPrice()
    }
  }
  async fetchPrice() {
    try {
      this.loading = true
      this.price = await this.$api.reservations(this.id).cancelPrice()
    } catch (err) {
      console.error(err)
      this.$toast.error('キャンセル料の取得に失敗しました。')
    }
    this.loading = false
  }

  async cancel(nocharge: boolean) {
    try {
      this.loading = true

      await this.$api.reservations(this.id).cancel(nocharge)
      this.dialog = false
      this.$toast.success('予約をキャンセルしました。')
      this.$emit('canceled', true) // close dialog
    } catch (err) {
      console.error(err)
      this.$toast.error('予約のキャンセルに失敗しました。')
    }
    this.loading = false
  }
}
