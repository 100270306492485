
































import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'

export interface Form {
  amount: number | null
  percentage: number | null
}

export const initForm = () => ({
  amount: null,
  percentage: null,
})

@Component({})
export default class ReservationOperationHistoryDyestuffForm2 extends Vue {
  @Model('change', { type: Object, default: null }) readonly value!: Form | null
  @Prop({ type: Object, default: () => ({}) }) readonly errors!: Dictionary<
    string[]
  >
  form = initForm()

  @Watch('value', { immediate: true, deep: true })
  onValueChange(value: Form | null) {
    if (!value) this.form = initForm()
    else if (value !== this.form) Object.assign(this.form, value)
  }

  onChange() {
    this.$emit('change', this.form)
  }
}
