var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_vm._t("activator",null,null,props)]}}],null,true),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 休憩時間変更 "+_vm._s(_vm.shift.staffName)+" ")]),_c('v-card-text',[_vm._v(" 勤務時間: "+_vm._s(_vm._f("time")(_vm.shift.start))+"~"+_vm._s(_vm._f("time")(_vm.shift.end))+" "),_c('v-form',[_vm._l((_vm.form.breaks),function(ref,idx){
var startTime = ref.startTime;
var endTime = ref.endTime;
return _c('v-layout',{key:idx + 'break',attrs:{"align-center":""}},[_c('span',{staticStyle:{"width":"80px"}},[_vm._v(" 休憩時間"+_vm._s(idx + 1)+" ")]),_c('TimeSelect',{staticStyle:{"max-width":"200px"},attrs:{"value":startTime,"clearable":"","min":_vm.minTime,"max":_vm.maxTime},on:{"change":function($event){return _vm.updateBreak(idx, { startTime: $event })}}}),_vm._v(" 〜 "),_c('TimeSelect',{staticStyle:{"max-width":"200px"},attrs:{"value":endTime,"min":_vm.minTime,"max":_vm.maxTime,"clearable":""},on:{"change":function($event){return _vm.updateBreak(idx, { endTime: $event })}}})],1)}),_c('v-divider'),_vm._l((_vm.form.blocks),function(ref,idx){
var startTime = ref.startTime;
var endTime = ref.endTime;
var text = ref.text;
return [_c('v-layout',{key:idx,attrs:{"align-center":""}},[_c('span',{staticStyle:{"width":"80px"}},[_vm._v(" 予定"+_vm._s(idx + 1)+" ")]),_c('v-layout',{attrs:{"column":"","justify-center":""}},[_c('v-layout',{attrs:{"align-center":""}},[_c('TimeSelect',{staticStyle:{"max-width":"200px"},attrs:{"value":startTime,"clearable":"","min":_vm.minTime,"max":_vm.maxTime},on:{"change":function($event){return _vm.updateBlock(idx, { startTime: $event })}}}),_vm._v(" 〜 "),_c('TimeSelect',{staticStyle:{"max-width":"200px"},attrs:{"value":endTime,"min":_vm.minTime,"max":_vm.maxTime,"clearable":""},on:{"change":function($event){return _vm.updateBlock(idx, { endTime: $event })}}})],1),_c('v-text-field',{attrs:{"value":text,"label":"理由"},on:{"change":function($event){return _vm.updateBlock(idx, { text: $event })}}})],1)],1),_c('v-divider',{key:idx + '-d'})]})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" 閉じる ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.submitting},on:{"click":_vm.submit}},[_vm._v(" 更新 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }