








































































import moment from 'moment'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Shift, TimeCard } from '@/types'
import ShiftUpdateDialog from '@/components/ShiftUpdateDialog.vue'

@Component({
  components: {
    ShiftUpdateDialog,
  },
})
export default class TimelineShiftHeader extends Vue {
  @Prop({ type: Object, required: true }) readonly shift!: Shift
  // @Prop({ type: Array, required: true }) readonly timeCards!: TimeCard[]
  @Prop({ type: String, required: true }) readonly date!: string

  @Prop({ type: [Number, String], default: null }) readonly width!:
    | null
    | string
    | number
  @Prop({ type: [Number, String], default: null }) readonly height!:
    | null
    | string
    | number

  loadingTimeCard = false
  waiting = false
  currentTimeCards: TimeCard[] = []
  showCancelEnter = false
  showCancelLeave = false

  get enableTimeCardBtn() {
    return moment().format('YYYY-MM-DD') === this.date
  }

  get sWidth() {
    let val = this.width
    if (typeof val === 'number') return val + 'px'
    return val
  }
  get sHeight() {
    let val = this.height
    if (typeof val === 'number') return val + 'px'
    return val
  }

  get sortedTimeCards() {
    let arr = this.currentTimeCards.map(x => x)
    arr.sort((a, b) => a.startTime.localeCompare(b.startTime))
    return arr
  }

  get isWorking() {
    return Boolean(this.currentTimeCards.find(x => x.endTime === null))
  }
  get latestTimeCard() {
    return this.sortedTimeCards[this.sortedTimeCards.length - 1] || null
  }

  // @Watch('shift', { immediate: true })
  // onShiftChanged(value: Shift) {
  //   // console.log('? shift', value)
  //   if (!value || value.isDummy) {
  //     this.currentTimeCards = []
  //   } else {
  //     this.fetchData()
  //   }
  // }

  // @Watch('isWorking', { immediate: true })
  // onWorkingStateChanged(value: boolean) {
  //   this.$emit('working', value)
  // }

  // async fetchData() {
  //   let { shop, date, staff } = this.shift
  //   if (!shop || !date || !staff) return
  //   this.loadingTimeCard = true
  //   try {
  //     let { results } = await this.$api.timeCards().list({
  //       shop,
  //       date,
  //       staff,
  //     })
  //     this.currentTimeCards = results
  //     this.loadingTimeCard = false
  //   } catch (err) {
  //     console.error()
  //   }
  // }

  // async cancelEnter() {
  //   const len = this.currentTimeCards.length
  //   const tc = this.currentTimeCards[len - 1]
  //   this.showCancelEnter = false
  //   if (!tc) return
  //   try {
  //     await this.$api.timeCards(tc.id).delete()
  //     await this.fetchData()
  //     this.$toast.success('入室をキャンセルしました。')
  //   } catch (err) {
  //     console.error(err)
  //     this.$toast.error('入室のキャンセルに失敗しました。')
  //   }
  // }

  // async cancelLeave() {
  //   const len = this.currentTimeCards.length
  //   const tc = this.currentTimeCards[len - 1]
  //   this.showCancelLeave = false

  //   if (!tc) return
  //   try {
  //     await this.$api.timeCards(tc.id).partialUpdate({ endTime: null })
  //     await this.fetchData()
  //     this.$toast.success('退室をキャンセルしました。')
  //   } catch (err) {
  //     console.error(err)
  //     this.$toast.error('退室のキャンセルに失敗しました。')
  //   }
  // }

  // async enter() {
  //   if (!confirm(`${this.shift.staffName} 入室中に変更しますか？`)) return
  //   let shift = this.shift
  //   let data = {
  //     shop: shift.shop,
  //     date: shift.date,
  //     staff: shift.staff,
  //   }
  //   this.loadingTimeCard = true
  //   try {
  //     this.currentTimeCards = await this.$api.timeCards().enter(data)
  //     this.$toast.success('入室しました')
  //     this.showCancelEnter = true
  //     this.showCancelLeave = false
  //     this.loadingTimeCard = false
  //   } catch (err) {
  //     console.error(err)
  //     if (err.status === 400) this.$toast.error(err.response.data)
  //     else this.$toast.error('サーバとの通信に失敗しました')
  //     this.loadingTimeCard = false
  //   }
  // }

  // async leave() {
  //   if (!confirm(`${this.shift.staffName} 退室中に変更しますか？`)) return

  //   let timecard = this.sortedTimeCards.find(x => x.endTime === null)
  //   if (!timecard) return
  //   this.loadingTimeCard = true

  //   try {
  //     this.currentTimeCards = await this.$api.timeCards(timecard.id).leave()
  //     this.$toast.success('退室しました')
  //     this.showCancelEnter = false
  //     this.showCancelLeave = true
  //     this.loadingTimeCard = false
  //   } catch (err) {
  //     console.error(err)
  //     if (err.status === 400) this.$toast.error(err.response.data)
  //     else this.$toast.error('サーバとの通信に失敗しました')
  //     this.loadingTimeCard = false
  //   }
  // }
}
